<template>
    <slot v-bind:data="data" v-bind:pending="pending" v-bind:refresh="refresh"></slot>
</template>
<script setup>
const props = defineProps({
    dataKey: { type: String, default: 'userroles' },
    getData: { type: Function,  default: () => { return [] } },
    filterData: { type: Function, default: (data) => { return data?.data; } },
    noRefresh: { type: Boolean, default: false },
})

const requestKey = computed(() => props.dataKey.toLowerCase() )

const { data, pending, refresh } = useLazyAsyncData(
    requestKey.value,
    props.getData
)
watch(requestKey, () => {
    if( !props.noRefresh )
        refresh()
})

</script>